import React from 'react';
import { graphql } from "gatsby"
import LegacyContainer from '../components/legacy-container';
import SEO from '../components/SEO';
import Gallery from '../components/modular/Gallery/Gallery'
import FullWidthImage from '../components/modular/FullWidthImage/FullWidthImage';
import ParagraphText from '../components/modular/ParagraphText/ParagraphText';
import Testimonials from '../components/modular/Testimonials/Testimonials';
import Soundcloud from '../components/modular/Soundcloud/Soundcloud';
import PressPack from '../components/modular/PressPack/PressPack';
import Spotify from '../components/modular/Spotify/Spotify';
import PageTitle from '../components/modular/PageTitle/PageTitle';

export default function Live({ data }) {
  const liveData = data.allMarkdownRemark.edges[0].node;
  const images = liveData.frontmatter.imageGallery.map(data => {
    return data.image;
  });
  const reviews = data.allMarkdownRemark.edges[0].node.frontmatter.reviews;
  const scLink = liveData.frontmatter.soundCloudEmbedLink;
  const spotifyLink = liveData.frontmatter.spotifyEmbedLink;
  const pressPackUrl = liveData.frontmatter.pressPackDownloadUrl;

  return (
    <LegacyContainer>
      <SEO pageTitle={liveData.frontmatter.heading} />
      <div className="content-container">
        <div className="content">
          <PageTitle title={liveData.frontmatter.heading} />
          <FullWidthImage image={liveData.frontmatter.headlineImage} />
          <ParagraphText html={liveData.html} />
          {reviews && reviews.length > 0 && <Testimonials reviews={reviews} />}
          {images && images.length > 0 && <Gallery images={images} />}
          {scLink && scLink.length > 0 && <Soundcloud scLink={scLink} />}
          {spotifyLink && spotifyLink.length > 0 && <Spotify spotifyLink={spotifyLink} />}
          {pressPackUrl && pressPackUrl.length > 0 && <PressPack pressPackUrl={pressPackUrl} />}

        </div>
      </div>
    </LegacyContainer >
  );
}

export const livePageQuery = graphql`
  query LivePageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "live-page" } }}
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            heading,
            headlineImage,
            imageGallery {
              image,
              description
            },
            reviews {
              name,
              review
            },
            pressPackDownloadUrl,
            soundCloudEmbedLink,
            spotifyEmbedLink
          }
          html
        }
      }
    }
  }
`
